import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateSoftware() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    text: '',
    title: '',
    price: 0,
    extra: '',
    image: '',
    video: '', // Add video state
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function handleChangeImage(event) {
    const { name, files } = event.target;
    if (name === "image" && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        const base64WithoutHeader = base64String.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        );
        setInputs((values) => ({
          ...values,
          image: base64WithoutHeader,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  function handleChangeVideo(event) {
    const { name, files } = event.target;
    if (name === "video" && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        const base64WithoutHeader = base64String.replace(
          /^data:video\/[a-z]+;base64,/,
          ""
        );
        setInputs((values) => ({
          ...values,
          video: base64WithoutHeader,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.posstore.az/posstore/softwareProgram.php/save", inputs)
      .then(function (response) {
        navigate("/dashboard/software-program");
      });
  };

  return (
    <div>
      <h1>Proqram təminatı əlavə et</h1>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
          <tr>
              <th>
                <label>Başlıq: </label>
              </th>
              <td>
                <input type="text" name="title" value={inputs.title} onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Yazı: </label>
              </th>
              <td>
                <textarea
                  name="text"
                  value={inputs.text}
                  onChange={handleChange}
                  rows="5"
                  cols="30"
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>Qiymət: </label>
              </th>
              <td>
                <input type="number" name="price" value={inputs.price} onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Qeyd: </label>
              </th>
              <td>
                <input type="text" name="extra" value={inputs.extra} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <th>
                <label>Şəkil: </label>
              </th>
              <td>
                <input type="file" name="image" onChange={handleChangeImage} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Video: </label>
              </th>
              <td>
                <input type="file" name="video" onChange={handleChangeVideo} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button type="submit">Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      {/* Burada metni <br> etiketlerini işleyerek gösteriyoruz */}
      <div>
        <h3>Saytda görünüşü</h3>
        <div
          dangerouslySetInnerHTML={{ __html: inputs.text.replace(/\n/g, "<br>") }}
        />
      </div>
    </div>
  );
}
