// import React, { useEffect, useState } from 'react';
// import Back from "../common/Back";
// import Heading from "../common/Heading";
// import img from "../images/about.jpg";
// import "./SoftwareProgram.css";
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const SoftwareProgram = () => {
//   const navigate = useNavigate();
//   const [list, setItems] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     getProducts();
//   }, []);

//   const getProducts = async () => {
//     try {
//       const response = await axios.get(
//         "https://www.posstore.az/posstore/softwareProgram.php/",
//         { withCredentials: false }
//       );
//       setItems(response.data);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toDetails = (id) => {
//     navigate(`/software-program/${id}`);
//   };

//   return (
//     <section className='blog-out mb'>
//       <Back name='Proqram təminatı' title='Proqram təminatı' cover={img} />
//       <div className='container recent'>
//         {isLoading ? (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} className='container'>
//             <img style={{ width: '50px', height: '50px', margin: '0 auto' }} src='/loading.gif' alt="Loading" />
//           </div>
//         ) : (
//           <div className='content grid3 mtop'> 
//             {list.length > 0 ? (
//               list.map((val) => {
//                 const { id, image, title } = val;
//                 return (
//                   <div onClick={() => toDetails(id)} className='box shadow' key={id}>
//                     <div className='img'>
//                       <img src={`data:image/jpeg;base64,${image}`} alt='Product' />
//                     </div>
//                     <div className='text'>
//                       <h4>{title}</h4>
//                     </div>
//                   </div>
//                 );
//               })
//             ) : (
//               <div className='no-results'>Proqram təminatı tapılmadı</div>
//             )}
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default SoftwareProgram;




































































import React, { useEffect, useState } from "react";
import Back from "../common/Back";
import img from "../images/about.jpg";
import "../home/recent/recent.css";
import axios from "axios";
import './aptek.css'
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/orebiSlice"; 
import { FaShoppingCart } from "react-icons/fa";

const SoftwareProgram = () => {
  const dispatch = useDispatch();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.posstore.az/posstore/softwareProgram.php/",
        { withCredentials: false }
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className='blog-out mb'>
        <Back name='Proqram təminatı' title='Proqram təminatı' cover={img} />
        <div className='container recent'>
          {isLoading ? (
           <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
           <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='./loading.gif'></img>
         </div>
          ) : (
            <div className='content grid4 mtop'>
              {list.length > 0 ? (
                list.map((val, index) => {
                  const { id, image, category, title, price } = val;
                  return (
                    <div className='box shadow' key={index}>
                      <div className='img'>
                        <img src={`data:image/jpeg;base64,${image}`} alt='' /> 
                      </div>
                      <div className='text'>
                        <div className='category flex'>
                          <span
                            style={{
                              background: category === "For Sale" ? "#25b5791a" : "#ff98001a",
                              color: category === "For Sale" ? "#25b579" : "#ff9800"
                            }}
                          >
                            {category}
                          </span>
                        </div>
                        <h4>{title}</h4>
                        <p style={{marginTop:'20px', fontWeight:'600', fontSize:'20px', marginBottom:'10px'}}> {price} AZN</p>
                        <a href={`/software-program/${id}`}><button className='details-button'>Ətraflı</button></a>
                        <button
  style={{
    marginTop: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: "pointer",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
      dispatch(
        addToCart({
          id: id,
          name: title,
          quantity: 1,
          image: image,
          price: price
        })
      );
  }}
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>
                      </div>
                    </div>
                  );
                })
              ) : ( 
                <div className='no-results'>No results found.</div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default SoftwareProgram;

