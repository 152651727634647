import React, { useEffect, useState } from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import RecentCard from "../../home/recent/RecentCard"
import img from "../../images/about.jpg"
import "../../home/recent/recent.css"
import RecentCard2 from "../../home/recent2/RecentCard2"
import axios from "axios"
import { FaShoppingCart } from "react-icons/fa"
import { addToCart } from "../../redux/orebiSlice";
import { useDispatch } from "react-redux"

const BarkodPrinter = () => {
  const dispatch = useDispatch();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const category = "Barkod Printer"; // Kategori adı "Barkod Oxuyucu" olarak ayarlandı

  useEffect(() => {
    getProducts(); // Kategoriyi değiştirmeye gerek yok, sabit
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.posstore.az/posstore/dashboard.php?category=${encodeURIComponent(category)}`, // Kategori değeri "Barkod Oxuyucu" olarak gönderiliyor
        { withCredentials: false }
      );

      if (Array.isArray(response.data)) {
        setItems(response.data);
      } else {
        console.error("Data is not an array:", response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  return (
    <>
        <section className='blog-out mb'>
        <Back name='Barkod printer' title='Avadanlıqlar-Barkod printer' cover={img} />
        <div className='container recent'>
        {isLoading ? (
           <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
           <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='./loading.gif'></img>
         </div>
          ) : (
        <div className='content grid3 mtop'>
        {list.map((val, index) => {
          const { id,image,category, name, price, discountPrice, count } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: category === "For Sale" ? "#25b5791a" : "#ff98001a", color: category === "For Sale" ? "#25b579" : "#ff9800" }}>{category}</span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p style={{marginBottom:"10px"}}>
                          <i className='fa fa-location-dot'></i> Bakı, Azərbaycan
                        </p>
                        <a href={`/product-detail/${id}`}><button className='details-button'>Ətraflı</button></a>
                        <button
  style={{
    marginTop: "1rem",
    backgroundColor: "#F1592A",
    color: "white",
    cursor: count == 0 ? "not-allowed" : "pointer",
    opacity: count == 0 ? 0.5 : 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
    if (count > 0) {
      dispatch(
        addToCart({
          id: id,
          name: name,
          quantity: 1,
          image: image,
          price: price,
          discountPrice: discountPrice,
        })
      );
    }
  }}
  disabled={count == 0} // Disable the button if count is 0
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>
{discountPrice > 0 ? <p style={{marginTop:'10px', fontWeight:'600', color:"green", fontSize:'20px'}}><span style={{color:'red'}}><del>{price} AZN</del></span> {discountPrice} AZN</p> :
                        <p style={{marginTop:'10px', fontWeight:'600', fontSize:'20px'}}> {price} AZN</p>}
              </div>
            </div>
          )
        })}
      </div>
      )}
        </div>
      </section>
    </>
  )
}

export default BarkodPrinter
