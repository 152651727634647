import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetCart } from "../redux/orebiSlice.js";
import ItemCard from "./ItemCard.js";
import Modal from "./Modal"; // Import the Modal component
import { toast } from "react-toastify";
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import axios from "axios";

const Cart = () => {

    const form = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [list, setItems] = useState([]);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const dispatch = useDispatch();
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState(""); 
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");


  useEffect(() => {
    let price = 0;
    products.map((item) => {
      price += (item.discountPrice > 0 ? item.discountPrice : item.price) * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [products]);
  

  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(5);
    } else if (totalAmt <= 400) {
      setShippingCharge(10);
    } else if (totalAmt > 401) {
      setShippingCharge(15);
    }
  }, [totalAmt]);




  const handleSubmitOrder = async () => {

    let productDetails = products.map((item) => {
        return `${item.quantity} eded ${item.name}`;
      }).join(', ');
      
      let inputs1 = {
        to_email: clientEmail,
        message: `Ad: ${clientName}, Email: ${clientEmail}, Nömrə: ${clientPhone}, Ünvan: ${clientAddress}, Məhsullar: ${productDetails}, Ümumi Qiymət: ${totalAmt} AZN`
      };

      let inputs11 = {
        to_email: 'namelum',
        message: `Ad: ${clientName}, Email: namelum, Nömrə: ${clientPhone}, Ünvan: ${clientAddress}, Məhsullar: ${productDetails}, Ümumi Qiymət: ${totalAmt} AZN`
      };
      
    

    if (clientEmail) {  // Eğer clientEmail boş değilse
      emailjs
        .send('service_fhagip7', 'template_a93qaml', inputs1, 'oPV8IO9EVD-P7f5sV')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      emailjs
        .send('service_fhagip7', 'template_a93qaml', inputs11, 'oPV8IO9EVD-P7f5sV')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    }




    let inputs2 = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: productDetails,
      totalAmt: totalAmt,
    };
    



    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs2).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
        swal("Sifarişiniz tamamlandı");
        dispatch(resetCart())
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };
  return (
  <>
  {console.log(products)}
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 16px" }}>
      {products.length > 0 ? (
        <div style={{ paddingBottom: "20px" }}>
          <div
            style={{
              width: "100%",
              height: "80px",
              backgroundColor: "#F5F7F7",
              color: "#333",
              display: "grid",
              gridTemplateColumns: "2fr 1fr 1fr 1fr",
              placeContent: "center",
              padding: "0 24px",
              fontSize: "18px",
              fontWeight: "600",
              display: "none",
            }}
            className="lgl"
          >
            <h2 style={{ gridColumn: "span 2" }}>Məhsul</h2>
            <h2>Qiymət</h2>
            <h2>Say</h2>
            <h2>Cəmi</h2>
          </div>
          <div style={{ marginTop: "20px" }}>
            {products.map((item) => (
              <div key={item.id}>
                <ItemCard item={item} />
              </div>
            ))}
          </div>

          <button
            onClick={() => dispatch(resetCart())}
            style={{
              padding: "8px 40px",
              backgroundColor: "#F1592A",
              color: "#fff",
              fontWeight: "600",
              textTransform: "uppercase",
              marginBottom: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#c00")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#f00")}
          >
            Səbəti boşalt
          </button>

          <div
            style={{
              maxWidth: "1120px",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              gap: "16px",
            }}
          >
            <div style={{ width: "384px", display: "flex", flexDirection: "column", gap: "16px" }}>
              <h1 style={{ fontSize: "24px", fontWeight: "600", textAlign: "right" }}>
                Ümumi Cəm
              </h1>
              <div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #ccc",
                    padding: "8px 16px",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Yekun Ödəniş
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>{totalAmt}₼</span>
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
  onClick={() => setIsModalOpen(true)}
  style={{
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor: "#F1592A",
    color: "white",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "340px",
  }}
>
  Sifariş ver
</button>
                  
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
        <div style={{ textAlign: "center", padding: "16px" }}>
          <img
            style={{
              width: "320px",
              borderRadius: "12px",
              padding: "16px",
              margin: "0 auto",
            }}
            src="./emptyCart.png"
            alt="emptyCart"
          />
        </div>
        <div
          style={{
            margin: '0 auto',
            maxWidth: "500px",
            padding: "16px",
            paddingTop: "32px",
            paddingBottom: "32px",
            backgroundColor: "white",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h1
            style={{
              fontFamily: "'Nunito Sans', sans-serif",
              fontSize: "1.25rem",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Səbətiniz boşdur
          </h1>
          <p
            style={{
              fontSize: "0.875rem",
              textAlign: "center",
              paddingLeft: "40px",
              paddingRight: "40px",
              marginTop: "-8px",
            }}
          >
            Biznesinizə az, Özünüzə isə daha çox vaxt ayırın
          </p>
          <Link to="/avadanliqlar">
            <button
              style={{
                backgroundColor: "#F1592A", 
                borderRadius: "8px", 
                cursor: "pointer",
                paddingLeft: "32px",
                paddingRight: "32px",
                paddingTop: "8px",
                paddingBottom: "8px",
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
                fontSize: "1.125rem",
                color: "#e2e8f0", // gray-200 equivalent
                transition: "background-color 0.3s, color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#3490dc";
                e.target.style.color = "#e2e8f0";
              }}
              onMouseDown={(e) => (e.target.style.backgroundColor = "#1c1c1c")}
              onMouseUp={(e) => (e.target.style.backgroundColor = "black")}
            >
              Məhsullara keçid et
            </button>
          </Link>
        </div>
      </>
      
      )}
    </div>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form ref={form} onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
               name="user_name"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail</label>
              <input
              name="user_email"
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
              name="user_number"
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
              name="user_address"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>

              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal></>
  );
};

export default Cart;
