// import React, { useEffect, useState } from "react";
// import Back from "../common/Back";
// import Heading from "../common/Heading";
// import img from "../images/about.jpg";
// import "./SoftwareProgramSingle.css";
// import { useParams } from "react-router-dom";
// import axios from "axios";

// const SoftwareProgramSingle = () => {
//   const { id } = useParams();
//   const [list, setItems] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     getProducts();
//   }, [id]);

//   const getProducts = async () => {
//     try {
//       const response = await axios.get(
//         `https://www.posstore.az/posstore/softwareProgram.php/${id}`
//       );
//       setItems([response.data]);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (isLoading) {
//     return   <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'50vh'}} className='container'>
//     <img style={{width:'50px', height:'50px', margin: '0 auto'}} src='/./loading.gif'></img>
//   </div>; // Simple loading text
//   }

//   return (
//     <>
//       <section className="market">
//         <Back name="Proqram təminatı" title="Proqram təminatı" cover={img} />
        
//         <div className="container flex mtop">
//           <div className="left row">
//             {list.map((val) => {
//               const { image, video, text, title, price, extra, id } = val;
//               return (
//                 <div key={id}>
//                   <p>{title}</p>
//                   <div className="right now">
//                     <img
//                       src={`data:image/jpeg;base64,${image}`}
//                       alt="Proqram təminatı"
//                     />
//                   </div>
//                   <p>{price} AZN</p>
//                   <p>{text}</p>
//                   <p>{extra}</p>
//                   {video && (
//                     <div className="video">
//                       <video width="320" height="240" controls>
//                         <source
//                           src={`data:video/mp4;base64,${video}`}
//                           type="video/mp4"
//                         />
//                         Your browser does not support the video tag.
//                       </video>
//                     </div>
//                   )}
                  
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default SoftwareProgramSingle;






























































































import React, { useEffect, useRef, useState } from "react";
import axios from "axios"; // You have imported axios but it's not used in the code. You can remove it if not needed.
import { useNavigate, useParams } from "react-router-dom";
import Back from "../common/Back";
import img from "../images/about.jpg";
import { FaShoppingCart } from "react-icons/fa";
import { addToCart } from "../redux/orebiSlice";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import Modal from "./Modal"; // Import the Modal component
 
export default function ProductDetail() {
  const params = useParams();
  const params2 = Number(params.id); // Parsing the product ID from the URL parameters
  const navigate = useNavigate();
  const form = useRef();

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState(""); 
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts();
  }, [params2]);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.posstore.az/posstore/softwareProgram.php/", {
        credentials: "omit",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data.filter((item) => Number(item.id) === params2));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleSubmitOrder = async () => {

    let inputs1 = {
      to_email: clientEmail,
      message: 'Ad: ' + clientName + ', Email: ' + clientEmail + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + list[0].title + ', Qiymət: ' + list[0].price + " AZN"
    };

    let inputs11 = {
      to_email: 'namelum',
      message: 'Ad: ' + clientName + ', Email: ' + 'namelum' + ', Nömrə: ' + clientPhone + ', Ünvan: ' + clientAddress + ', Məhsul: ' + list[0].title + ', Qiymət: ' + list[0].price + " AZN"
    };
    

    if (clientEmail) {  // Eğer clientEmail boş değilse
      emailjs
        .send('service_fhagip7', 'template_a93qaml', inputs1, 'oPV8IO9EVD-P7f5sV')
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      emailjs
      .send('service_fhagip7', 'template_a93qaml', inputs11, 'oPV8IO9EVD-P7f5sV')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    }




    let inputs2 = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      address: clientAddress,
      products: list[0].title,
      totalAmt:  list[0].price,
    };
    



    try {
      axios.post("https://www.posstore.az/posstore/orders.php/", inputs2).then(function (response) {
        navigate("/");
        swal("Sifarişiniz tamamlandı");
        setclientName("");
        setclientEmail("");
        setclientPhone("");
        setclientAddress("");
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Sifariş gönderilirken bir hata oluştu:", error);
    }
  };

  return (
    <>
      <Back name="Proqram təminatı" title="Avadanlıqlar-Proqram təminatı haqqında" cover={img} />
      <div className="container" style={{ margin: "0 auto", padding: "3rem 0" }}>
        <div className="flex-center">
          {isLoading ? (
            <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <img style={{ width: '50px', height: '50px' }} src="/loading.gif" alt="Loading" />
            </div>
          ) : list.length > 0 ? (
            list.map((val) => {
              const { id, image, price, title, text, video } = val;
              return (
                <div key={id} className="flex">
                  <div style={{ width: '50%' }}>
                    <div className='box'>
                      <div className='img'>
                        <img style={{ width: '50%' }} src={`data:image/jpeg;base64,${image}`} alt={title} />
                        <h4 style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem", color: '#A7A7A7' }}>{title}</h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "50%" }} className="product-info">
                    {text && <h4 style={{ fontSize: "24px", fontWeight: "600" }}>Məlumat: </h4>}
                    {text && (
                      <h4 style={{ fontSize: "20px", marginBottom: "1rem" }} dangerouslySetInnerHTML={{ __html: text }} />
                    )}
                    {price > 0 && <p style={{ fontSize: "1.5rem", fontWeight: "700", marginBottom: "1rem" }}>{price} AZN</p>}
                    <button
  onClick={() => setIsModalOpen(true)}
  style={{
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor: "#F1592A",
    color: "white",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "340px",
    cursor:"pointer",
  }}
>
  Sifariş ver
</button>

<button
  style={{
    marginTop: "1rem",
    padding: "1rem 2rem",
    backgroundColor:  "#F1592A",
    color: "white",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "340px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
  onClick={() => {
      dispatch(
        addToCart({
          id: id,
          name: title,
          quantity: 1,
          image: image,
          price: price,
        })
      );
  }}
>
  Səbətə əlavə et
  <span style={{ marginLeft: "0.2rem" }}>
    <FaShoppingCart />
  </span>
</button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No data found</p>
          )}
          {list.length > 0 && list[0].video && (
            <div className="video">
              <video width="520" height="400" controls>
                <source src={`data:video/mp4;base64,${list[0].video}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} style={{ color: "white", cursor: "pointer" }}>
              &#10005;
            </button>
          </div>
          <form ref={form} onSubmit={handleSubmitOrder}>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ad, soyad*</label>
              <input
               name="user_name"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Adınızı daxil edin"
                onChange={(event) => setclientName(event.target.value)}
                value={clientName}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>E-mail</label>
              <input
              name="user_email"
                type="email"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Emailinizi daxil edin"
                onChange={(event) => setclientEmail(event.target.value)}
                value={clientEmail}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Əlaqə nömrəsi*</label>
              <input
              name="user_number"
                type="number"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Telefon nömrənizi daxil edin"
                onChange={(event) => setclientPhone(event.target.value)}
                value={clientPhone}
                required
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label style={{ display: "block", marginBottom: "0.5rem", color: "#4a5568" }}>Ünvan*</label>
              <input
              name="user_address"
                type="text"
                style={{ width: "100%", padding: "0.75rem", borderRadius: "10px", border: "1px solid #e2e8f0", backgroundColor: "#edf2f7" }}
                placeholder="Ünvanınızı daxil edin"
                onChange={(event) => setclientAddress(event.target.value)}
                value={clientAddress}
                required
              />
            </div>
            <div>
            <div className="flex-center" style={{ display: "flex", justifyContent: "space-between" }}>
        
        {isLoading ? (
          <div style={{ height:'200px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
<img style={{ width: '50px', height: '50px' }} src="/./loading.gif" alt="Loading" />
</div>

        ) : list.length > 0 ? (
          list.map((val, index) => {
            const { id, image, name, category,price, discountPrice, model, processor,count, generation, ram, ssd, ghz, touchscreen, size } = val;
            return (
                <>
                <div style={{ marginBottom: "1rem", color: discountPrice > 0 ? "green" : "black" }}>
    {discountPrice > 0 ? (
     <p>Məhsul endirimdədir! <span style={{color:'red'}}> <del>{price} AZN</del> </span>{discountPrice} AZN</p>


    ) : (
      <p>Məhsulun qiyməti: {price} AZN</p>
    )}
  </div>
               </>
            );
          })
        ) : (
          <p>No data found</p>
        )}
    </div>

              <button
                type="submit"
                style={{
                  padding: "0.75rem 1.5rem",
                  backgroundColor: "#F1592A",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Sifariş ver
              </button>
            </div>
          </form>
      </Modal>
    </>
  );
}

